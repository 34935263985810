<template>
	<div>
		<CBreadcrumb :items="links" style="margin-top: -28px;"/>
		<CCard>
			<CCardBody>
				<TheTableHeader
					:header="'FORMULIR PENGATURAN'"
					:subHeader="'DATA RUANGAN DATA CENTER DI LINTASARTA.'"
				/>
			</CCardBody>
		</CCard>
		<CRow>
			<CCol sm="6">

				<CCard>
					<CCardHeader>
						<strong>Form {{id > 0 ? 'Perubahan' : 'Penambahan'}}</strong> Data Ruangan di Data Center.
					</CCardHeader>
					<CCardBody>
						<CForm>
							<CInput
								type="text"
								description=""
								label="ID Ruangan"
								v-model="id"
								plaintext
								readonly
							/>
							<CSelect 
								:value.sync="site_id"
								name="site_id"
								label="Nama Data Center"
								placeholder="Pilih Data center"
								:options="sites"
							/>
							<CInput
								type="text"
								description=""
								label="Nama Ruangan"
								placeholder="Masukkan nama ruangan.."
								v-model="room_name"
								required
							/>
						</CForm>
					</CCardBody>
					<CCardFooter>
						<CButton type="submit" size="sm" color="success" @click="id === '0' ? createRoom() : updateRoom()"><CIcon name="cil-check-circle"/> Save</CButton> &nbsp;&nbsp;
						<CButton type="reset" size="sm" color="danger" @click="resetForm()"><CIcon name="cil-ban"/> Clear</CButton> &nbsp;&nbsp;
						<CButton type="cancel" size="sm" color="primary" @click="goBack()"><CIcon name="cil-ban"/> Cancel</CButton>
					</CCardFooter>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import http from '@/utils/http-common';
import TheTableHeader from '@/containers/TheTableHeader.vue'

export default {
	name: 'RoomManagement',
	components: { TheTableHeader },
	data () {
		return {
			id: this.$route.params.id,
			site_id: 0,
			room_name: '',
			sites: [],
			links: [
        {
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'Daftar Ruangan',
          href: '#/settings/rooms'
        }, 
        {
          text: 'Manajemen Ruangan'
        }
      ],
		}
	},
	methods: {
		toast(message, type) {
      this.$toast.open({
        message: message,
        type: type, // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 3000,
        dismissible: true
      })
    },
		goBack(){
      this.$router.go(-1);
    },
		getSites() {
			const self = this;
			return http.get('/sites')
			.then(function (response) {
				const data = response.data.data;
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['site_name'];
					items['value'] = data[i]['id'];
					self.sites.push(items)
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		createRoom() {
			let self = this;
			return http.post('/rooms', {
				site_id: self.site_id,
				room_name: self.room_name
			})
			.then(function (response) {
				self.toast('Ruangan Berhasil Ditambahkan.', 'info')
				self.$router.go(-1);
			}).catch(function (error) {
				self.toast(error, 'error')
				console.log(error);
			});
		},
		updateRoom() {
			let self = this;
			return http.put('/rooms/' + self.id, {
				site_id: self.site_id,
				room_name: self.room_name
			})
			.then(function (response) {
				self.toast('Ruangan Berhasil Diubah.', 'info')
				self.$router.go(-1);
			}).catch(function (error) {
				self.toast(error, 'error')
				console.log(error);
			});
		},
		resetForm() {
			let self = this;
			self.site_id = 0;
			self.room_name = '';
		},
		getRoom(id) {
			let self = this;
			if (id !== '0') {
				return http.get('/rooms/' + id)
				.then(function (response) {
					self.site_id = response.data.data.site_id;
					self.room_name = response.data.data.room_name;
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	mounted: function(){
		this.getRoom(this.id);
		this.getSites();
	}
}
</script>
